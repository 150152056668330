import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import theme from "./theme";
import "./mui-tokens.css";
import "./mui-reset.css";
import "./mui-base.css";
import Icons from "./components/Icons";
import Header from "./components/PageLayout/Header";
import Projects from "./components/PageLayout/Projects/Projects";
import Footer from "./components/PageLayout/Footer";
import Skills from "./components/PageLayout/Skills/Skills";
import CaseStudies from "./components/PageLayout/CaseStudies/CaseStudies";
import Resume from "./components/PageLayout/Resume/Resume";

// Global Styles
import GlobalStyles from "./components/GlobalStyles";
import ScrollToTop from "./components/ScrollToTop";

const Navigation = styled.nav`
  margin-bottom: var(--spacing-medium);
  @media only screen and (min-width: 768px) {
    margin-bottom: var(--spacing-clear);
    padding: 2.4rem;
  }
`;

const NavigationInner = styled.ul`
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-column-gap: 1.2rem;
    grid-template-columns: 1fr auto;
  }
`;

const NavigationGroup = styled.li`
  padding-left: 0;
  text-indent: 0;
  margin-bottom: 0;
  display: grid;
  @media only screen and (min-width: 768px) {
    grid-column-gap: 1.2rem;
  }
`;

const NavigationGroupMain = styled(NavigationGroup)`
  @media only screen and (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
  }
  @media only screen and (min-width: 500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const NavigationGroupSocial = styled(NavigationGroup)`
  display: none;
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, auto);
  }
`;

const StyledLink = styled(Link)`
  background-color: #333333;
  display: inline-block;
  text-align: center;
  color: white;
  height: auto;
  text-decoration: none;
  padding: 0px 2.4rem;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5), 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.4rem;
  line-height: 3;
  border-bottom: none;
  &:hover {
    color: #ff73df;
    background: rgba(0, 0, 0, 0.25);
  }
  &:hover,
  &:focus-visible {
    color: #ff73df;
    border-bottom: none;
  }
  &:focus {
    color: #ff73df;
  }
  &:focus-visible {
    outline-color: #ff73df;
  }
  @media only screen and (min-width: 500px) {
    box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
      0 -1px 0 0 rgba(255, 255, 255, 0.1);
  }
  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
`;

const SocialLink = styled.a`
  height: 4.8rem;
  width: 4.8rem;
  background-color: #333333;
  display: inline-block;
  text-align: center;
  color: white;
  text-decoration: none;
  padding: 0.6rem;
  border-radius: 100%;
  line-height: 3;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.5),
    0 -1px 0 0 rgba(255, 255, 255, 0.1);
  border-bottom: none;
  &:hover {
    color: #ff73df;
    background: rgba(0, 0, 0, 0.25);
  }
  &:hover,
  &:focus-visible {
    color: #ff73df;
    border-bottom: none;
  }
  &:focus {
    color: #ff73df;
  }
  &:focus-visible {
    outline-color: #ff73df;
  }
  @media only screen and (max-width: 768px) {
    border-radius: 0;
  }
  svg {
    fill: white;
  }
  &:hover svg,
  &:focus svg {
    fill: #ff73df;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        <Router>
          <ScrollToTop />
          <Navigation id="back-to-top">
            <NavigationInner>
              <NavigationGroupMain>
                <StyledLink to="/">Home</StyledLink>
                <StyledLink to="/skills">Skills</StyledLink>
                <StyledLink to="/case-studies">Case Studies</StyledLink>
                <StyledLink to="/resume">Resume</StyledLink>
              </NavigationGroupMain>
              <NavigationGroupSocial>
                <SocialLink
                  target="_blank"
                  alt="Codepen"
                  title="Codepen"
                  href="http://codepen.io/trilm"
                >
                  <Icons type="codepenIcon" />
                </SocialLink>
                <SocialLink
                  target="_blank"
                  alt="LinkedIn"
                  title="LinkedIn"
                  href="http://nz.linkedin.com/pub/michael-trilford/14/831/5a0/"
                >
                  <Icons type="inIcon" />
                </SocialLink>
                <SocialLink
                  href="mailto:trilfordm@gmail.com"
                  alt="Contact me"
                  title="Contact me"
                >
                  <Icons type="emailIcon" />
                </SocialLink>
                <SocialLink
                  target="_blank"
                  href="https://docs.google.com/document/d/1kCfF1tTDmtPWLG6auWC7NZ2wKEyPwdLXKjN7x09-yzk/edit?usp=sharing"
                  alt="Resume"
                  title="Print Resume"
                >
                  <Icons type="docIcon" />
                </SocialLink>
              </NavigationGroupSocial>
            </NavigationInner>
          </Navigation>
          <Route exact path="/" component={HomePage} />
          <Route path="/skills" component={SkillsPage} />
          <Route path="/case-studies" component={CaseStudiesPage} />
          <Route path="/resume" component={ResumePage} />
        </Router>
      </React.Fragment>
    </ThemeProvider>
  );
}

function HomePage() {
  return (
    <>
      <Header />
      <Projects />
      <Footer />
    </>
  );
}

function SkillsPage() {
  return (
    <>
      <Skills />
      <Footer />
    </>
  );
}

function CaseStudiesPage() {
  return (
    <>
      <CaseStudies />
      <Footer />
    </>
  );
}

function ResumePage() {
  return (
    <>
      <Resume />
      <Footer />
    </>
  );
}

export default App;
