import React from "react";
import styled from "styled-components";
import Icons from "../../../Icons";
import Badge from "../../../Badge";
import Links from "../../../Links";
import ProjectTitle from "../ProjectTitle";
import ProjectSection from "../ProjectSection";
import ProjectSectionSkills from "../ProjectSectionSkills";
import ProjectDescription from "../ProjectDescription";

const MuiProjectSection = styled(ProjectSection)`
  background: #37373d;
`;

const CodepenIframe = styled.iframe`
  width: 100%;
  display: block;
  height: 45.2rem;
  padding: 5.106382978723%;
  padding-bottom: 0px;
  min-height: 14.41667rem;
  background: #000000;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-bottom: 0;
  border: none;
`;

const CodePenLink = styled(Links)`
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  &:hover,
  &:focus {
    color: white;
    border-bottom: none;
  }
  &:focus {
    outline-color: white;
  }
`;

function VirtualCard() {
  return (
    <MuiProjectSection>
      <ProjectTitle title="Virtual Card" />
      <ProjectSectionSkills>
        <Badge small title="UI Design">
          <Icons type="rgba-small" />
        </Badge>
        <Badge small title="HTML">
          <Icons type="html-small" />
        </Badge>
        <Badge small title="CSS">
          <Icons type="css-small" />
        </Badge>
      </ProjectSectionSkills>
      <ProjectDescription>
        At Airwallex, I explored a fun way to make virtual cards stand out from
        physical ones, both represented in-product. After a quick team chat, I
        prototyped a{" "}
        <CodePenLink
          href="https://codepen.io/trilm/pen/raBGaQQ"
          target="_blank"
          color="white"
        >
          CodePen
        </CodePenLink>{" "}
        concept using mesh gradients and looping transitions, creating a
        vibrant, ever-shifting digital aesthetic. This playful exercise
        reimagined how digital cards could feel more dynamic and alive.
      </ProjectDescription>

      <CodepenIframe
        height="452"
        scrolling="no"
        title="Typography on the web"
        src="https://codepen.io/trilm/embed/raBGaQQ?default-tab=result&theme-id=dark"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen="true"
      >
        See the Pen{" "}
        <a href="https://codepen.io/trilm/pen/raBGaQQ">Typography on the web</a>{" "}
        by Michael Trilford (<a href="https://codepen.io/trilm">@trilm</a>) on{" "}
        <a href="https://codepen.io">CodePen</a>.
      </CodepenIframe>
    </MuiProjectSection>
  );
}

export default VirtualCard;
